<template>
    <div>
        <el-card style="margin-top: 8px">
            <div slot="header" class="card-header">
                <span>内购/领用</span>
            </div>

            <!-- 内购/领用-->
            <el-card style="margin-top: 20px">
                <!--标题-->
                <el-row class="title" :gutter="100" type="flex">
                    <el-col :span="4" class="content1">
                        <el-button type="text" @click="jumpToReceive" class="font1" :disabled="!hasReceivePrivilege">
                            内购/领用
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font2">
                        <span>
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForCreateReceive"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            可新建内购单
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForReviewReceive"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可审核内购单</span>
                        </span>
                        <span style="padding-left: 100px">
                            <el-tooltip
                                class="font2"
                                style="text-decoration: underline"
                                :content="roleNamesForStockReceive"
                                placement="top"
                            >
                                <span>此类角色</span>
                            </el-tooltip>
                            <span>可出/入库</span>
                        </span>
                    </el-col>
                </el-row>
                <!--步骤-->
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToReceive"
                            :disabled="!hasCreateReceivePrivilege"
                            >①新建内购单</el-button
                        >
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            点击新建-选择机构名称--选择摘要-点击选择商品基本信息-选择需要内购的商品-点击确定-填写内购单价和内购数量-点击保存
                        </span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToReceive"
                            :disabled="!hasReviewReceivePrivilege"
                        >
                            ②审核内购单
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要审核的内购单-点击审核-审核成功后可以出库</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToReceive"
                            :disabled="!hasStockReceivePrivilege"
                        >
                            ③出库内购商品
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>勾选需要内购的单据-点击出库-完成后内购的库存会被扣除</span>
                    </el-col>
                </el-row>
                <el-row :gutter="100" type="flex" style="height: 50px; margin-top: 8px">
                    <el-col :span="4" class="content2" style="text-decoration: underline">
                        <el-button
                            type="text"
                            class="font2"
                            @click="jumpToReceive"
                            :disabled="!hasReviewReceivePrivilege"
                        >
                            ④内购数量不正确
                        </el-button>
                    </el-col>
                    <el-col :span="20" class="content2 font3">
                        <span>
                            如审核后发现内购数量有误可以进行反审核；在需要反审核的单据上点击查看-审核详情-反审核
                            -确定后可以点击此单据的编辑按钮进行重新填写-之后重新执行审核内购单操作(已出库的单据不能反审核)
                        </span>
                    </el-col>
                </el-row>
            </el-card>
        </el-card>
    </div>
</template>

<script>
import Util from 'js/Util';

const receivePrivilegeFlag = 'menu.stock.receive';
const openReceivePrivilegeFlag = `${receivePrivilegeFlag}.open`;
const createReceivePrivilegeFlag = `${receivePrivilegeFlag}.create`;
const reviewReceivePrivilegeFlag = `${receivePrivilegeFlag}.review`;
const stockReceivePrivilegeFlag = `${receivePrivilegeFlag}.stock`;

export default {
    name: 'ReceiveGuide',
    data() {
        return {
            rolesForCreateReceive: [],
            rolesForReviewReceive: [],
            rolesForStockReceive: [],
        };
    },
    created() {
        //查询"能够创建领用单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(createReceivePrivilegeFlag).then((rst) => {
            this.rolesForCreateReceive = rst;
        });
        //查询"能够审核领用单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(reviewReceivePrivilegeFlag).then((rst) => {
            this.rolesForReviewReceive = rst;
        });
        //查询"能够出库领用单据"的角色
        this.$efApi.roleApi.queryRolesByPrivilegeFlag(stockReceivePrivilegeFlag).then((rst) => {
            this.rolesForStockReceive = rst;
        });
    },
    computed: {
        roleNamesForCreateReceive() {
            return this.rolesForCreateReceive.map((e) => e.name).join('，');
        },
        roleNamesForReviewReceive() {
            return this.rolesForReviewReceive.map((e) => e.name).join('，');
        },
        roleNamesForStockReceive() {
            return this.rolesForStockReceive.map((e) => e.name).join('，');
        },
    },

    methods: {
        jumpToReceive() {
            Util.nameJump(this, receivePrivilegeFlag);
        },

        hasReceivePrivilege() {
            return this.hasPrivilege(openReceivePrivilegeFlag);
        },
        hasCreateReceivePrivilege() {
            return this.hasPrivilege(createReceivePrivilegeFlag);
        },
        hasReviewReceivePrivilege() {
            return this.hasPrivilege(reviewReceivePrivilegeFlag);
        },
        hasStockReceivePrivilege() {
            return this.hasPrivilege(stockReceivePrivilegeFlag);
        },
    },
};
</script>

<style scoped>
.font1 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: bold;
    text-decoration: underline;
    color: #36bf5d;
    line-height: 28px;
}

.font2 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #606266;
    line-height: 20px;
}

.font3 {
    font-size: 16px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #9ca1ab;
    line-height: 20px;
}
.title {
    background: #d6ffe2;
    border-radius: 4px 4px 0 0;
}

.content1 {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.content2 {
    height: 100%;
    display: flex;
    justify-content: left;
    align-items: center;
}

.el-row {
    height: 70px;
}

.el-button.is-disabled,
.el-button.is-disabled:focus,
.el-button.is-disabled:hover {
    color: #c0c4cc;
    cursor: not-allowed;
}

/deep/ .el-card__body {
    padding-top: 0;
}

.card-header {
    font-size: 20px;
    font-family: PingFang SC, sans-serif;
    font-weight: 400;
    color: #212121;
    line-height: 28px;
}
</style>
